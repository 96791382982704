.chef{
    padding: 2rem 2rem;
}

.app__chef-content{
    display:flex;
    flex-direction: column;
    width:100%;
    margin-top:2rem;
}
.img_container{
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: top;
    margin-left: 2rem;
    margin-right: 2rem;
}

.img_container img{
    object-fit: cover;
    width:100%;
}

@media screen and (max-width: 2000px) {
    .app__wrapper_img{
        width:300px;
    }
}


@media screen and (max-width: 650px) {
    .app__chef-content{
        margin-top: 0rem;
    }
    
}