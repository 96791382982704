.super{
    display:flex;
    flex-direction: column;
    background-color: var(--color-black);
    padding:4rem 0rem 4rem 2rem;
    justify-content: center;
}
.app__gallery{
    display:flex;
    flex-direction: row;
    background-color: var(--color-black);
    padding:4rem 0rem 4rem 2rem;
}

.app__gallery-content{
    flex:1;
    display:flex;
    justify-content:center;
    align-items: flex-start;
    flex-direction: column;
    padding-right: 1rem;
}

.a__gallery-content_p{
    font-family: var(--font-alt);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 20px;
    color:'#AAA';
    margin-Top:'2rem';
}

.app__gallery-images{
    flex:1;
    display:flex;
    flex-direction: row;
    max-width:60%; /*why??*/
    position: relative;
}

.headtext__cormorant_gallery{
    font-family: var(--font-base);
    margin-top: -1rem;
    margin-left: 1rem;
    margin-bottom: -1rem;
    color: var(--color-golden);
    font-size: 64px;
    line-height: 83.2px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
}

.app__gallery-images_container{
    margin-top: -2rem;
    display:flex;
    width:max-content;
    flex-direction: row;
    overflow-x: scroll;
    /*Makes overflowing x to be scrolled*/
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-bottom: -2rem;
}

.app__gallery-images_container::-webkit-scrollbar{
    display:none;
    
}

.app__gallery-images_card {
    position: relative;
    min-width: 400px;
    height:300px;
    margin-right:2rem;
}

.app__gallery-images_card img{
    height:100%;
    margin-right:2rem;
    width:100%;
    object-fit: cover;
    border-radius: 10%;
}

.app__gallery-images_arrows{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items:center;

    padding:0 1rem;
    position:absolute;
    bottom:5%;
}

.gallery__arrow-icon{
    color: var(--color-golden);
    font-size:2rem;
    cursor:pointer;
    background-color: var(--color-black);
    border-radius: 5px;

}

.gallery__arrow-icon:hover{
    color:var(--color-white);
}


@media screen and (min-width:2000px)
{
    .app__gallery-content{
        min-width:1000px;
        padding-right:4rem;
    }
}

@media screen and (max-width:1150px)
{
    .app__gallery{
        flex-direction: column;
    }
    .app__gallery-images{
        max-width: 75%;
        margin: 5rem 0rem;
    }
    .app__gallery-images_card {
        max-width: 450px;
        max-height:250px;
    }
}

@media screen and (max-width:850px)
{
    .app__gallery{
        padding:4rem 0rem 4rem 4rem;
    }
    .a__gallery-content_p{
        font-family: var(--font-alt);
        color: var(--color-white);
        font-weight: 400;
        letter-spacing: 0.04em;
        line-height: 15px;
        font-size: 12px;
        color:'#AAA';
        margin-Top:'1rem';
    }
}

@media screen and (max-width:650px)
{
    .super{
        padding:1rem;
    }
    .app__gallery{
        padding: 1rem 0rem 0rem 0rem;
    }
    .app__gallery-content{
        max-width:80%;
        max-height:50%;
        justify-content: flex-start;
    }

    .app__gallery-images_card{
        min-width: 240px;
    }
    .mobileVersion {
        font-size: 10px;
    }
    
}