.app__navbar{
    width:100%;
    display:flex;
    border:2px solid black;
    justify-content: space-between;
    background: var(--color-black);
    padding: 1rem 2rem;
}

.app__navbar-logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family:var(--font-base);
    cursor: pointer;
}

.app__navbar-logo img{
    max-height:75px;
}

.app__navbar-links{
    flex:1;
    display:flex;
    justify-content: center;
    align-items: center;
    list-style:none;
    margin-top: -1rem;
    margin-right: 5rem;
}

.app__navbar-links li{
    margin: 0 1rem;
    cursor:pointer;
}

.app__navbar-links li:hover{
    color:var(--color-golden);
    margin: 0 1rem;
    cursor:pointer;
}

.app__navbar-login{
    display:flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: -1rem;
    display: none;
}

.app__navbar-login a{
    margin: 0 1rem;
    transition:0.5s ease;
}

.app__navbar-login a:hover{
    color:var(--color-golden);
    border-bottom: 1px solid var(--color-golden);
}

.app__navbar-login div{
    
    width:1px;
    height:30px;
    background-color:var(--color-grey);
}

.app__navbar-smallscreen{
    display:none;
}

.app__navbar-smallscreen_overlay{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    background:var(--color-black);
    transition:0.5s ease;
    flex-direction: column;
    display:flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.app__navbar-smallscreen_overlay li{
    list-style: none;
    color:var(--color-golden);
    margin:2rem;
    font-size:2rem ;
    cursor:pointer;
    font-family:var(--font-base);

}
.app__navbar-smallscreen_overlay li:hover{
    color:var(--color-white);
}
.app__navbar-smallscreen_overlay .overlay_close{
    font-size:27px;
    color:var(--color-golden);
    cursor:pointer;

    position:absolute;
    top:20px;
    right:20px;
}


@media screen and (max-width:2000px)
{
}


@media screen and (max-width:1150px)
{
    .app__navbar-links{
        display:none;
    }

    .app__navbar-smallscreen{
        display:flex;
    }
}



@media screen and (max-width:650px)
{
    .app__navbar-login{
        display:none;
        /* display none removes display*/
    }
}

