.app__header{
    background-color: var(--color-black);
    padding: 4rem 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
    margin-top:-6rem;
    margin-bottom:0rem;
    padding-bottom: 0rem;
}
.app__wrapper_info{
    padding: 0 2 0rem;
    display: flex;
    flex-direction: column;
    height:60%;
}
.app__header-h1{
    font-family:var(--font-base);
    color:var(--color-golden);
    letter-spacing: 0.02em;
    text-transform: uppercase ;
    line-height:75px;
    font-size:70px;
    margin-top: 0rem;
    width:100%;
    display:flex;
    flex-direction: column;
}
.app__header-img{
}
.app__header-img img{
    width: 100%;
}

@media screen and (min-width:2000px)
{
    .app__header-h1{
    }
}

@media screen and (max-width:1150px)
{
    .app__header-h1{
    }
}

@media screen and (max-width:850px)
{
    .app__header{
        background-color: var(--color-black);
        padding:0rem 0rem 0rem 0rem;   
    }   

    .app__header-h1{
        font-size: 50px;
        line-height:75px;
        padding:0rem 0rem 0rem 0rem;   
    }
}

@media screen and (max-width:650px)
{
    .app__header{
        flex-direction: column;
        padding-top: 4rem;
        padding-left: 2rem;
        padding-right: 1rem;
        width:100%;
        margin-top:-3rem;
    }
    .app__header-h1{
        font-size: 50px;
        line-height:75px;
    }

}